import { useCallback } from "react"
import { apiRequest } from "src/api"

export type SupportRequest = {
  email: string
  subject: string
  type:
    | "feedback"
    | "help"
    | "concierge"
    | "beta"
    | "support"
    | "sales"
    | "churnsurvey"
    | "demo"
  name: string
  text: string
}

export function useSupportRequest() {
  const supportRequest = useCallback((request: SupportRequest) => {
    return apiRequest(
      "support_requests",
      {
        support_request: request,
      },
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
        },
      },
    )
  }, [])

  return supportRequest
}
