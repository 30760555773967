import { useState, useRef, useCallback } from "react"

export function useHasBeenFocusedField() {
  const [state, setState] = useState<Record<string, boolean>>({})
  const fieldSetRef = useRef(new Set<string>())
  fieldSetRef.current.clear()

  const hasBeenFocused = useCallback(
    (name: string) => {
      return state[name] || false
    },
    [state],
  )

  const field = useCallback((name: string) => {
    fieldSetRef.current.add(name)

    return {
      onFocus: () => {
        setState(current => {
          const status = current[name]

          return status
            ? current
            : {
                ...current,
                [name]: true,
              }
        })
      },
    }
  }, [])

  const focusAll = useCallback(() => {
    const newState = Array.from(fieldSetRef.current).reduce(
      (prev, next) => ({ ...prev, [next]: true }),
      {} as typeof state,
    )

    setState(newState)
  }, [])

  return {
    hasBeenFocused,
    field,
    focusAll,
  }
}
